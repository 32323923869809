import React from "react"
import { Link } from "gatsby"

const BannerHeader = ({ background, title, servicesDetails }) => {
  return (
    <div className="page-content">
      <div
        className="dlab-bnr-inr overlay-black-light bg-pt bannerHeader"
        style={{ backgroundImage: "url(" + background + ")", height: "250px" }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry" style={{ height: "250px" }}>
            <h1 className="text-white">{title}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">Home</Link>
                </li>
                {servicesDetails && (
                  <>
                    {/* <li>Products and Services</li> */}
                    <li>Services</li>
                  </>
                )}
                <li>{title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerHeader
